@use "@angular/material" as mat;
@include mat.elevation-classes();
@include mat.app-background();

body {
  font-family: "Merriweather Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

$md-primary: (
  50: #e5eafd,
  100: #becafa,
  200: #93a7f6,
  300: #6783f2,
  400: #4769f0,
  500: #1c3de8,
  600: #2247eb,
  700: #1c3de8,
  800: #1735e5,
  900: #0d25e0,
  A100: #ffffff,
  A200: #d9dcff,
  A400: #a6adff,
  A700: #8c96ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// $md-primary: (
//   50: #e0e0f2,
//   100: #b3b3df,
//   200: #8080ca,
//   300: #4d4db4,
//   400: #2626a4,
//   500: #1c3de8,
//   600: #00008c,
//   700: #000081,
//   800: #000077,
//   900: #000065,
//   A100: #9595ff,
//   A200: #6262ff,
//   A400: #2f2fff,
//   A700: #1515ff,
//   contrast: (
//     50: #000000,
//     100: #000000,
//     200: #000000,
//     300: #ffffff,
//     400: #ffffff,
//     500: #ffffff,
//     600: #ffffff,
//     700: #ffffff,
//     800: #ffffff,
//     900: #ffffff,
//     A100: #000000,
//     A200: #ffffff,
//     A400: #ffffff,
//     A700: #ffffff,
//   ),
// );

$md-accent: (
  50: #e1f1e8,
  100: #b3dcc5,
  200: #80c59f,
  300: #4dae78,
  400: #279c5b,
  500: #018b3e,
  600: #018338,
  700: #017830,
  800: #016e28,
  900: #005b1b,
  A100: #8cffa3,
  A200: #59ff7a,
  A400: #26ff52,
  A700: #0dff3d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-warn: (
  50: #fae5e4,
  100: #f3bebc,
  200: #eb938f,
  300: #e36762,
  400: #dd4740,
  500: #dc0907,
  // 500 #d7261e
  600: #d3221a,
  700: #cd1c16,
  800: #c71712,
  900: #be0d0a,
  A100: #ffe9e9,
  A200: #ffb7b6,
  A400: #ff8483,
  A700: #ff6b69,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.m2-define-palette($md-primary, 700);
$my-accent: mat.m2-define-palette($md-accent, 500);
$my-warn: mat.m2-define-palette($md-warn, 500);

$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

@include mat.all-component-themes($custom-theme);

.divider {
  border-color: #1c3de8 !important;
  border-width: 3px !important;
  border-radius: 25px !important;
}

.divider-black {
  border-color: #000000 !important;
  border-width: 3px !important;
  border-radius: 25px !important;
}

th.mat-header-cell,
td.mat-cell {
  font-size: 1em !important;
  font-weight: 500 !important;
  color: black;
}

table {
  width: 100%;
}

td,
th {
  width: fit-content !important;
  text-align: center !important;
  margin: none;
}

tr.mat-row {
  border-bottom: rgba(204, 204, 204, 0.315) !important;
}

/* Estilos para navegadores Webkit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 6px; /* Ancho del scroll */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Bordes redondeados */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color al pasar el mouse */
}

/* Estilos para Firefox */
* {
  scrollbar-width: thin; /* Ancho del scroll */
  scrollbar-color: #888 #f1f1f1; /* Color de la barra de desplazamiento y el track */
}

.avatar {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 25px;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  margin: 0;
  width: 30px;
}

.avatar-img {
  border-radius: 50%;
  height: 40px;
  margin: 0;
  width: 40px;
}
